.css-2b097c-container{
	position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}
.css-26l3qy-menu{
	z-index:99999!important
}
.loader,
.loader:before,
.loader:after {
	background: #25405b;
  	-webkit-animation: load1 1s infinite ease-in-out;
  	animation: load1 1s infinite ease-in-out;
  	width: 1em;
  	height: 4em;
}
.loader {
	color: #25405b;
	text-indent: -9999em;
	margin: 88px auto;
	position: relative;
	font-size: 11px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.loader:before,
.loader:after {
	position: absolute;
  	top: 0;
  	content: '';
}
.loader:before {
	left: -1.5em;
  	-webkit-animation-delay: -0.32s;
  	animation-delay: -0.32s;
}
.loader:after {
 	left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.fc-toolbar h2 {
    font-size: 16px!important;
    line-height: 40px;
    text-transform: uppercase;
    margin: 0;
}
.fc-button {
    background: #ffffff!important;
    border: 1px solid #a8a8b1!important;
    color: #6c757d!important;
    font-family: inherit!important;
    text-transform: capitalize!important;
    padding: 6px 16px!important;
}
.fc-button:hover, .fc-button:focus{
    background-color: #5766da!important;
    color: #ffffff!important;
    border-color: #5766da!important;
}
.fc-button-active{
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    background-color: #5766da!important;
    border-color: #5766da!important;
    color: #ffffff!important;
    text-shadow: none!important;
}
.fc-col-header-cell.fc-day{
    font-size: 15px!important;
    line-height: 20px!important;
    padding: 15px 0!important;
    text-transform: uppercase!important;
    font-weight: 600!important;
    background-color: #5766da!important;
    color: #ffffff!important;
    border-color: transparent!important;

}
.fc-col-header-cell.fc-day a{
    color: #ffffff!important; 
}
.fc-daygrid-event.bg-soft-primary{
    border-left: 5px solid!important;
    color: #3788d8!important;
}
.bg-soft-primary .fc-event-title{
    color: #3788d8!important;   
}
.sidebar-user img.img-thumbnail{
  height: 64px!important;
}
@media (max-width: 991px){
    #navigation.open{
        display: block;
        position: fixed;
    }
}
.topbar .topbar-left .logo .logo-sm{
  height: 46px;
}
@media (max-width: 1024px){
    .topbar .topbar-left {
        width: 263px;
    }
    .topbar .topbar-left h2{
        font-size: 15px;
        width: 198px;
        line-height: 15px;
        position: relative;
        top: 10px;
    }
}
@media (max-width: 1199px){
    .topbar .topbar-left {
        width: 263px;
    }
}
@media(max-width: 991px){
    .action-buttons{
        position: fixed;
        bottom: 0;
        width: calc(100% - 20px);
        left: 10px;
        z-index: 999;
    }
}
@media (min-width: 1200px){
    .topbar .navbar-toggle {
        display: none !important;
    }
    .topbar .topbar-left{
        width: auto;
    }
}
@media (max-width: 1199px){
    .page-wrapper .page-wrapper-inner {
        padding: 0;
        margin-bottom: 20px;
    }
    #navigation {
        position: fixed;
    }
    #navigation {
        top: 70px;
        left: 0;
        right: 0;
        display: none;
        height: auto;
        padding-bottom: 0;
        overflow: auto;
        background-color: #ffffff;
        z-index: 1000;
    }
}
@media (max-width: 767px){
    .topbar .topbar-left {
        width: 200px;
    }
    .topbar .topbar-left h2{
        display: none!important
    }
    .page-wrapper .navigation-menu > li > a{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .page-wrapper .navigation-menu{
        -webkit-box-shadow: 0px 10px 24px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 10px 24px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 10px 24px 0px rgba(0,0,0,0.75);
        border-bottom: 1px solid #cccccc;
    }
    .fc .fc-toolbar{
        display: block !important;
        text-align: center;
    }
    .fc-toolbar-chunk{
        margin: 20px 0;
    }
    .page-wrapper-img{
        min-height: auto
    }
}
#modalQuiz .modal-body *{
    color: #333;
}
.bg-feita{
    color: #2f394e;
    background-color: #f8f9fa;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(87, 102, 218, 0.33);
    box-shadow: 0 0 0 0.2rem rgba(87, 102, 218, 0.33);
    z-index: 2;
    text-decoration: none;
    border-color: #dee2e6;
}
#gabarito-list{
    padding-left: 0;
}
#gabarito-list div .alert-text p{
    margin-bottom: 0;
}
@media (max-width: 768px){
    .page-title-box {
        margin-top: 0px;
    }
    .aluno-name{
        margin-top: 70px;
        padding: 15px 26px;
        background: #ccc;
    }
}
img, video, iframe{
    max-width: 100% !important;
}
@media (max-width: 991px){
    .page-wrapper .navigation-menu {
        max-height: 100%;
    }
    #navigation.open {
        display: block;
        position: fixed;
        z-index: 9999;
        max-height: 250px;
    }
}
@media(min-width: 1024px){
    .page-title-box{
        float: right;
        text-align: right;
    }
}
#togglePwd{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index:  10;
}
.upload_comments{
    display: table;
    width: 100%;
}
.upload_comment{
    border-radius: 10px;
    padding: 10px;
    width: 70%;
    color: #ffffff!important
}
.upload_comment p{
    color: #ffffff!important;
    font-size: 16px;
}
.upload_comment p small{
    color: #ffffff!important;
}
.m_comment{
    float: right;
    background: rgba(87, 102, 218, 0.33)
}
.u_comment{
    float: left;
    background: rgba(87, 102, 218, 0.9) 
}
.upload_comment:not(:last-child){
    margin-bottom: 20px;
}